@import 'src/styles/variables';

.container {
  display: flex;
  justify-content: center;
  padding-top: pxToRem(140);

  @include breakpoints-down($tablet-lg) {
    padding-top: pxToRem(40);
  }

  @include breakpoints-down($phone-lg) {
    padding-top: pxToRem(48);
  }
}

.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0;
  width: 20vw;
  max-height: 20vw;
  @include breakpoints-down($tablet-lg) {
    width: 50vw;
    max-height: 20vw;
  }
}

.logo {
  max-width: 100%;
  height: auto;
  max-height: pxToRem(175);
}
