@import 'src/styles/variables';

.wrapper {
  background-color: $site-bg;
  border-radius: pxToRem(16);
  padding: pxToRem(24) pxToRem(16);
  box-shadow: $box-shadow-md;
  margin: pxToRem(48) 0 pxToRem(48);

  @include breakpoints-down($phone-lg) {
    margin: pxToRem(40) 0 pxToRem(40);
  }
}

.divider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: pxToRem(16);

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: pxToRem(1);
    background-color: $border-gray;
  }
}

.dividerTitle {
  position: relative;
  background-color: $site-bg;
  padding: 0 pxToRem(16);
  z-index: 1;
}

.carousel {
  display: flex;
  flex-wrap: wrap;
  padding: 0 pxToRem(48);
}

.arrow {
  width: pxToRem(48);
  height: pxToRem(48);
  border-radius: 50%;
  background-color: $site-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: pxToRem(12);
  position: absolute;
  z-index: 1;
  top: pxToRem(36);
  box-shadow: $box-shadow-lg;
  cursor: pointer;
  transition: transform .3s ease-out;

  &:focus {
    outline: none;
  }

  > div {
    width: pxToRem(20);
    height: pxToRem(20);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.prevArrow {
  left: pxToRem(-24);

  &:hover {
    transform: translateX(pxToRem(-2)) scale(1.05);
  }
}

.nextArrow {
  right: pxToRem(-24);

  &:hover {
    transform: translateX(pxToRem(2)) scale(1.05);
  }
}