@import 'src/styles/variables';

.slide {
  display: block;
  flex: 1 1 33.3334%;
  text-decoration: none;
  color: $font-dark;
  margin-right: pxToRem(12);
  margin-left: pxToRem(12);
}

.image {
  height: pxToRem(120);
  margin-bottom: pxToRem(4);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.title {
  font-size: pxToRem(14);
  line-height: pxToRem(21);
}