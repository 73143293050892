@import 'src/styles/variables';

.homePageWrapper {
  overflow: hidden;
}

.homePageWrapperWithBottomSpace {
  padding-bottom: pxToRem(48);
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.searchWrapper {
  padding-top: pxToRem(48);
  padding-bottom: pxToRem(48);
}

.tabsHeader {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  padding-bottom: pxToRem(28);
}

.virtualGraduationSectionWrapper {
  width: 100%;
  margin-top: pxToRem(40);
  margin-bottom: pxToRem(80);

  iframe {
    max-width: 100% !important;
  }
}

.ceremonyCard {
  margin-bottom: pxToRem(40);
}

.buttonWrapper {
  margin-bottom: pxToRem(40);
  display: flex;
  justify-content: center;
}

.box {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  text-align: center;
}
