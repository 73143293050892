.container {
  overflow: hidden;
  position: relative;
  width: 100%;

  &:after {
    padding-top: 56.25%;
    display: block;
    content: '';
    width: 100%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
