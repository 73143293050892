@import "src/styles/mixins";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: pxToRem(20);
}

.videoPlaceHolder {
  padding-top: 56.25%;
  width: 100%;
}
