@import 'src/styles/variables';

.card {
  display: block;
  box-shadow: $box-shadow-lg;
  border-radius: pxToRem(10);
  overflow: hidden;
  height: 100%;
  text-decoration: none;
  color: $font-dark;
  transition: transform .3s ease-out;

  &:hover {
    transform: scale(1.02) translateY(pxToRem(-3));
  }
}

.imageWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    width: pxToRem(50);
    height: pxToRem(50);
    border-radius: 50%;
    background: $orange;
    opacity: 0.8;
    position: absolute;
  }

  &:before {
    content: '';
    border-left: pxToRem(20) solid $site-bg;
    border-top: pxToRem(10) solid transparent;
    border-bottom: pxToRem(10) solid transparent;
    position: absolute;
    z-index: 1;
    margin-left: pxToRem(6);
  }
}

.image {
  width: 100%;
  display: block;
}

.content {
  padding: pxToRem(24);
  display: flex;
}

.title {
  font-size: pxToRem(14);
  line-height: 1.5;
  font-weight: 900;
  backface-visibility: hidden;
}

.subtitle {
  color: #242323;
  margin-top: pxToRem(2);
  backface-visibility: hidden;
  line-height: pxToRem(20);
}

.share {
  margin-top: pxToRem(12);
  padding-left: pxToRem(24);
  display: flex;
  justify-content: flex-end;
  flex: 1;

  svg {
    &:focus {
      outline: none;
    }
  }
}

.shareDropMenu {
  a {
  color: $dark-blue !important;
  }
}

.shareDropMenu {
  a {
  color: $dark-blue !important;
  }
}