@import 'src/styles/variables';

.mostRecentWrapper {
  padding-bottom: pxToRem(88);

  @include breakpoints-up($desktop-sm) {
    padding-bottom: pxToRem(140);
  }
}

.col {
  margin-bottom: pxToRem(24);
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: pxToRem(32) 0 pxToRem(88);

  @include breakpoints-up($desktop-sm) {
    padding-bottom: pxToRem(140);
  }
}